<template>
 <div class="s_task_list_order_task_pop">
    <el-dialog :close-on-press-escape="false" :title="'查看详情'" class="s-task-dialog" top="80px" width="1100px" :close-on-click-modal="false" :visible.sync="form">
                <el-form :model="formData" label-width="140px" size="mini">
                  <el-tabs v-model="activeName" type="card">
                    <!-- 选择类型 -->
                    <el-tab-pane label="选择类型" name="1">
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="商城类型:">
                              <span>{{formData.platform.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="店铺名称:">
                              <span>{{formData.userShop.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="店铺账号:">
                              <span>{{formData.userShop.account}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务类型:">
                              <span>{{formData.platformPlay.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24" v-show="formData.entrance">
                            <el-form-item label="活动单类型:">
                              <span>{{formData.entrance}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="本金返款模式:">
                              <span v-if="formData.fk_mode == 0">平台返款</span>
                              <span v-if="formData.fk_mode == 1">商家返款</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        
                    </el-tab-pane>
                    <!-- 试用活动商品信息 -->
                    <el-tab-pane label="试用活动商品信息" name="2">
                      <el-row>
                        <el-col :span="24">
                            <el-form-item label="商品链接:">
                              <span>{{formData.url}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="商品标题:">
                              <span>{{formData.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="详情页验证关键词:">
                              <span>{{formData.detail_keyword}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="宝贝示例图片:">
                              <el-row :gutter="20">
                                <el-col :span="3" v-for="(item,index) in formData.img" :key="index">
                                  <el-image  style="width: 100px; height: 100px" fit="cover" :src="item" :preview-src-list="formData.img"></el-image>
                                </el-col>
                              </el-row>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="6">
                            <el-form-item label="搜索排序方式:">
                              <span>{{formData.search_sort}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item label="筛选条件备注:">
                              <span>{{formData.search_remark}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="6">
                            <el-form-item label="搜索展示价:">
                              <span>{{formData.search_price}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item label="拍下件数:">
                              <span>{{formData.buy_num}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="6">
                            <el-form-item label="宝贝下单价:">
                              <span>{{formData.actual_price}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item label="是否包邮:">
                              <span>{{formData.is_free ? '包邮':'不包邮' }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row v-show="formData.entrance">
                          <el-col :span="16" v-show="formData.entrance === '淘口令'">
                            <el-form-item label="淘口令:">
                              <span>{{formData.tkl}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="16">
                            <el-form-item label="入口描述:" >
                              <span>{{formData.entrance_remark}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <!-- v-show="formData.entrance" -->
                        <el-row v-show="formData.entrance" >
                          <el-col :span="15">
                            <el-form-item label="步骤截图:">
                              <el-col :span="3" v-for="(item,index) in formData.entrance_pic" :key="index">
                                  <el-image style="width: 100px; height: 100px" fit="cover" :src="item" :preview-src-list="[formData.entrance_pic]"></el-image>
                                </el-col>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row v-for="(item, index) in formData.goods" :key="index">
                          <div class="s-hr" v-show="formData.goods.length > 1"></div>
                          <el-col :span="24">
                            <el-form-item :label="'附加商品链接' + (index+1) + ':'">
                              <span>{{item.url}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item :label="'附加商品图片'+ (index+1) + ':'">
                               <el-image  style="width: 100px; height: 100px" fit="cover" :src="item.img" :preview-src-list="[item.img]"></el-image>
                            </el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item :label="'附加商品价格' + (index+1) + ':'">
                              <span>{{item.price}}元</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item :label="'附加商品拍下件数' + (index+1) + ':'">
                              <span>{{item.num}}件</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!-- 选择任务类型和单数 -->
                    <el-tab-pane label="选择任务类型和单数" name="3">
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务类型:">
                              <span>{{formData.task_type.title}} {{formData.task_type.id}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24" v-if="formData.entrance=='淘口令' && formData.tkl">
                            <el-form-item label="淘口令:">
                              <span>{{ formData.tkl }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24" v-if="formData.platform_play_id==306 && formData.tkl">
                            <el-form-item label="入口链接:">
                              <span>{{ formData.tkl }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24" v-if="formData.platform_play_id==306 && formData.entrance_code">
                            <el-form-item label="入口二维码:">
                                <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.entrance_code" :preview-src-list="[formData.entrance_code]"></el-image>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24" v-if="formData.entrance_store">
                            <el-form-item label="进店链接:">
                              <span>{{ formData.entrance_store }} </span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <!-- 普通好评 -->
                        <div v-if="formData.task_type.id == 10">
                          <el-row v-for="(item,index) in formData.search_keyword" :key="index" class="g-padtop20 g-padbtom20">
                            <el-col :span="8">
                              <el-form-item :label="'搜索关键词'+(index+1)+':'">
                                <el-input placeholder="请输入内容"  v-model="item.keyword" readonly></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item :label="'任务数量'+(index+1)+':'">
                                <span>{{item.num}}单</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>

                        <!-- 文字好评 -->
                        <div v-if="formData.task_type.id == 11">
                          <el-row v-for="(item,index) in formData.search_keyword" :key="index" class="g-padtop20 g-padbtom20">
                            <el-col :span="8">
                              <el-form-item :label="'搜索关键词'+(index+1)+':'">
                                <el-input placeholder="请输入内容"  v-model="item.keyword" readonly></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item :label="'指定文字任务数量'+(index+1)+':'">
                                <span>{{item.num}}单</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="24">
                              <el-form-item :label="'指定文字内容'+(index+1)+':'">
                                <span>{{item.reviews}}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>

                        <!-- 图片好评 -->
                        <div v-if="formData.task_type.id == 12">
                          <el-row :gutter="30" v-for="(item,index) in formData.search_keyword" class="g-padtop20 g-padbtom20" :key="index">
                            <el-col :span="8">
                              <el-form-item :label="'搜索关键词'+(index+1)+':'">
                                <el-input placeholder="请输入内容"  v-model="item.keyword" readonly></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item :label="'图片好评任务数量'+(index+1)+':'">
                                <span>{{item.num}}单</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item :label="'图片好评商品规格'+(index+1)+':'">
                                <span>{{item.size}}</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="24">
                              <el-form-item :label="'图片好评晒图'+(index+1)+':'">
                                <el-row :gutter="30">
                                  <el-col :span="4" v-for="(item2, index2) in item.img" :key="index2">
                                    <el-image style="width: 100px; height: 100px" fit="cover" :src="item2" :preview-src-list="item.img"></el-image>
                                  </el-col>
                                </el-row>
                              </el-form-item>
                            </el-col>
                            <el-col :span="24">
                              <el-form-item :label="'指定文字内容'+(index+1)+':'">
                                <span>{{item.reviews}}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>

                    </el-tab-pane>
                    <!-- 试用活动要求 -->
                    <el-tab-pane label="试用活动要求" name="4">
                        <el-row v-show="formData.xdsj.title">
                          <el-col :span="24">
                            <el-form-item label="下单时间:">
                              <span>{{formData.xdsj.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="货比商品:">
                              <span>{{formData.huobi.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="浏览副宝贝:">
                              <span>{{formData.liulan.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="主宝贝浏览:">
                              <span>{{formData.main_liulan.map(item => item.title).join(',')}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="付款方式:">
                              <span>{{formData.pay_type}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="操作中截图:">
                              <span>{{formData.screenshot == '0' ? '不截图': '截图' }}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="确认收货截图:">
                              <span>{{formData.screenshot_arrival == '0' ? '不截图': '截图' }}</span>
                            </el-form-item>
                          </el-col>
                          
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="特殊说明:">
                              <span>{{formData.explain}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="特殊说明示例图:">
                              <el-row :gutter="30">
                                <el-col :span="3" v-for="(item, index) in formData.explain_img" :key="index">
                                  <el-image style="width: 100px; height: 100px" fit="cover" :src="item" :preview-src-list="formData.explain_img"></el-image>
                                </el-col>
                              </el-row>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="活动参与人群标签与资格" name="5">
                      <el-row>
                          <el-col :span="24">
                            <el-form-item label="商家审号服务:">
                              <span>{{formData.task_pass ? '开启' : '关闭'}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="开通花呗:">
                              <span>{{formData.credit_pay ? '需要': '不需要'}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="信誉要求:">
                              <span>{{formData.xinyu.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="性别限制:">
                              <span>{{formData.sex === 0 ? '不限制': (formData.sex === 1 ? '男号': '女号' )}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="年龄限制:">
                              <span>{{formData.age === 0 ? '不限制': ((formData.age === 1) ? '18~25岁': ((formData.age === 2) ? '26-35岁' : '35岁以上')) }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="地区限制:">
                              <span>{{formData.region}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="店铺申请限制:">
                              <span v-show="formData.shop_limit === 0">不限制</span>
                              <span v-show="formData.shop_limit !== 0">{{formData.shop_limit}} 天内禁止申请该店铺的试用商品</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="发布申请限制:">
                              <span v-show="formData.account_limit === 0">不限制</span>
                              <span v-show="formData.account_limit !== 0">{{formData.account_limit}}天 内禁止申请我账号下发布的试用商品</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                         <el-row>
                          <el-col :span="24">
                            <el-form-item label="加赏任务佣金:">
                              <span>{{formData.reward}}元 / 单</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </el-tab-pane>

                    <el-tab-pane label="试用活动发布方式" name="6">
                      <el-row>
                          <el-col :span="24">
                            <el-form-item label="发布方式:">
                              <span>{{formData.time_type === 1 ? '立即发布' : '小时发布'}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row v-show="formData.time_type === 1">
                          <el-col :span="24">
                            <el-form-item label="任务发布时间:">
                              <span>{{ formatDate(formData.time_val, 'yyyy-MM-dd hh:mm:ss')  }} </span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row v-show="formData.time_type === 2">
                          <el-col :span="24">
                            <el-form-item label="任务开始日期:">
                              <span>{{ formData.start_date }} </span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="任务开始时刻:">
                              <span>{{ formData.startTime +':00' }} </span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="任务开始时刻:">
                              <span>{{ formData.endTime +':00' }} </span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务发布间隔:">
                              <span>{{Number(formData.time_interval)}} 分钟</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <!-- <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务回收时长:">
                              <span>{{ taskTimeList.find(item => item.value === formData.close_time).name}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row> -->
                        
                    </el-tab-pane>
                  </el-tabs>

                  <!-- <el-row>
                    <el-col :span="18">
                      <el-form-item label="审核不通过理由:">
                         <el-input placeholder="请输入审核不通过理由" type="textarea" v-model="formData.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row> -->
                </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button @click="form = false">取 消</el-button>
            <el-button type="primary" @click="form = false">确定</el-button>
        </div>
    </el-dialog>

 </div>
</template>

<script>
import { formatDate } from '@/utils/index.js'
export default {

  data() {
    return {
      formData: {
        img: [],
        remark: '',
        userShop: { account : '' },
        platform: { title: '' },
        platformPlay: { title: '' },
        goods: [],
        task_type: { title: '', id: '' },
        search_keyword: [],
        entrance_pic: [],
        xdsj: { title: '' },
        huobi: { title: '' },
        liulan: { title: '' },
        main_liulan: [{ title: '' }],
        explain_img: [],
        xinyu: { title: '' },
        shop_limit: 0,
        account_limit: 0,
        close_time: 0,
        time_interval: 0,
        time_val: 0,
        
      },
      form: false,
      activeName: '1',
      // 任务回收时间
      taskTimeList: [
        { name: '2小时', value: 2 },
        { name: '4小时', value: 4 },
        { name: '8小时', value: 8 },
        { name: '12小时', value: 12 },
        { name: '24小时', value: 24 },
        { name: '48小时', value: 48 },
        { name: '当天24点', value: 0 },
      ],
    }
  },
  methods: {
    formatDate,
    onShow(data) {
      this.formData = data
      console.log(this.formData)
      this.form = true
    },

  }
}
</script>

<style lang='scss'>

</style>