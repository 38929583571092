<template>
  <div class="s_task_list_order_task_pop">
    <el-dialog :close-on-press-escape="false" :title="'订单详情'" class="s-task-dialog" top="80px" width="900px" :close-on-click-modal="false" @close="onClose" :visible.sync="show">
      <el-form :model="formData" label-width="150px" size="mini">
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane label="订单信息" name="1">
            <el-row>
              <el-col :span="8">
                <el-form-item label="任务编号:">
                  <span>{{ formData.user_task_id }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="订单编号:">
                  <span>{{ formData.id }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="订单状态:">
                <span  v-show="formData.status === 0">已取消</span>
                <span  v-show="formData.status === 10">待接单</span>
                <span  v-show="formData.status === 20">待审核</span>
                <span  v-show="formData.status === 30">操作中</span>
                <span  v-show="formData.status === 40">待返款</span>
                <span  v-show="formData.status === 50">待收货</span>
                <span  v-show="formData.status === 60">待完成</span>
                <span  v-show="formData.status === 80">已完成</span>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="商城类型:">
                  <span>{{ formData.platform.title }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="本金返款模式:">
                  <span v-if="formData.fk_mode == 0">平台返款</span>
                  <span v-if="formData.fk_mode == 1">商家返款</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="商品链接:">
                  <span>{{ formData.url }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-show="formData.kw_data.keyword">
                <el-form-item label="搜索关键词:">
                  <span>{{ formData.kw_data.keyword }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-show="formData.entrance_store">
                <el-form-item label="进店链接:">
                  <span>{{ formData.entrance_store }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" v-if="formData.entrance=='淘口令' && formData.tkl">
                <el-form-item label="淘口令:">
                  <span>{{ formData.tkl }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" v-if="formData.platform_play_id==306 && formData.tkl">
                <el-form-item label="入口链接:">
                  <span>{{ formData.tkl }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" v-if="formData.platform_play_id==306 && formData.entrance_code">
                <el-form-item label="入口二维码:">
                    <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.entrance_code" :preview-src-list="[formData.entrance_code]"></el-image>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="任务类型:">
                  <span>{{ formData.task_type.title }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="formData.kw_data.size">
                <el-form-item label="指定尺寸:">
                  <span>{{ formData.kw_data.size }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="formData.kw_data.img.length">
                <el-form-item :label="'图片好评晒图'">
                  <el-row :gutter="30">
                    <el-col :span="4" v-for="(item, index) in formData.kw_data.img" :key="index">
                      <el-image style="width: 100px; height: 100px" fit="cover" :src="item" :preview-src-list="formData.kw_data.img"></el-image>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="formData.kw_data.reviews">
                <el-form-item label="指定评价:">
                  <span>{{ formData.kw_data.reviews }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="详情页验证关键词:">
                  <span>{{ formData.detail_keyword }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="订单总金额:">
                  <span>{{ formData.total_price }}元</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="下单金额:">
                  <span>{{ formData.ben_price }}元</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="基础服务费:">
                  <span>{{ formData.service_price }}元</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="基础佣金:">
                  <span>{{ Math.formatFloat(Number(formData.base_price) + Number(formData.flow_price), 2) }}元</span>
                </el-form-item>
              </el-col>
               <el-col :span="8">
                <el-form-item label="增值服务费:">
                  <span>{{ formData.vat_price }}元</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="下单价:">
                  <span>{{ formData.actual_price }}元</span>
                </el-form-item>
              </el-col>
               <el-col :span="8">
                <el-form-item label="下单数:">
                  <span>{{ formData.buy_num }}件</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="操作中是否截图:">
                  <span>{{ formData.screenshot | filterScreenshot }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="确认收货是否截图:">
                  <span>{{ formData.screenshot_arrival | filterScreenshot }}</span>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="s-hr"></div>
            <div v-show="formData.userOrder.id">
              <div class="s-user-order">买家填写的信息:</div>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="店铺名称:">
                    <span>{{ formData.userOrder.shop_name }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="详情页验证关键词:">
                    <span>{{ formData.userOrder.order_detail_keyword }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-show="formData.userOrder.shop_enter_keyword_url">
                <el-col :span="8">
                  <el-form-item label="进店关键词链接:">
                    <span>{{ formData.userOrder.shop_enter_keyword_url }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="支付订单编号:">
                    <span>{{ formData.userOrder.pay_order_number }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="实际付款金额:">
                    <span>{{ formData.userOrder.real_pay_price }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-for="(item2, index2) in formData.userOrder.huobi_url" :key="'huobi'+ index2">
                <el-col :span="8">
                  <el-form-item :label="'货比链接' + (index2+1) + ':'">
                    <span>{{ item2.name }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-for="(item, index) in formData.userOrder.fbb_url" :key="'fbb' + index">
                <el-col :span="8">
                  <el-form-item :label="'副宝贝链接' + (index+1) + ':'">
                    <span>{{ item.name }}</span>
                  </el-form-item>
                </el-col>
              </el-row>

              <!-- 截图 -->
               <el-row>
                <el-col :span="8" v-show="formData.userOrder.goods_img">
                  <el-form-item label="主宝贝截图:">
                    <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.userOrder.goods_img" :preview-src-list="[formData.userOrder.goods_img]"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="8"  v-show="formData.userOrder.enter_shop_img">
                  <el-form-item label="进店截图:">
                    <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.userOrder.enter_shop_img" :preview-src-list="[formData.userOrder.enter_shop_img]"></el-image>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8"  v-show="formData.userOrder.pay_img">
                  <el-form-item label="付款截图:">
                    <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.userOrder.pay_img" :preview-src-list="[formData.userOrder.pay_img]"></el-image>
                  </el-form-item>
                </el-col>
              </el-row>

              <div class="s-hr" v-if="formData.userOrder.receive_goods_img || formData.userOrder.comment_img"></div>
              <!-- 确认收货 -->
              <el-row v-if="formData.userOrder.receive_goods_img || formData.userOrder.comment_img">
                <el-col :span="8"  v-if="formData.userOrder.receive_goods_img">
                  <el-form-item label="确认收货截图:">
                    <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.userOrder.receive_goods_img" :preview-src-list="[formData.userOrder.receive_goods_img]"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="8"  v-if="formData.userOrder.comment_img">
                  <el-form-item label="评价截图:">
                    <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.userOrder.comment_img" :preview-src-list="[formData.userOrder.comment_img]"></el-image>
                  </el-form-item>
                </el-col>
              </el-row>
            
            </div>
           





          </el-tab-pane>
          <el-tab-pane label="用户信息" name="2" v-if="formData.userAccount.id">
            <el-row>
              <el-col :span="24">
                <el-form-item label="买家ID:">
                  <span>{{formData.userAccount.id}}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="买家账号:">
                  <span>{{formData.userAccount.account}}</span>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="formData.fk_mode == 1">
              <el-col :span="12">
                <el-form-item label="买家银行名称:">
                  <span>{{formData.buy.bank.bank_name}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="买家银行账号:">
                  <span>{{formData.buy.bank.card_number}}</span>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="formData.fk_mode == 1">
              <el-col :span="12" v-if="formData.buy.alipay.name">
                <el-form-item label="买家支付宝名字:">
                  <span>{{formData.buy.alipay.name}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12"  v-if="formData.buy.alipay.bank_name">
                <el-form-item label="买家支付宝账号:">
                  <span>{{formData.buy.alipay.bank_name}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="formData.buy.alipay.pay_img">
                <el-form-item label="买家支付宝二维码:">
                  <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.buy.alipay.pay_img" :preview-src-list="[formData.buy.alipay.pay_img]"></el-image>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <el-row v-if="seeStatus == 1 || seeStatus == 2 || seeStatus == 5">
          <el-col :span="18">
            <el-form-item label="不通过理由:">
                <el-input placeholder="请输入不通过理由" type="textarea" v-model="formData.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button @click="show = false" v-show="seeStatus === 4">取 消</el-button>
          <el-button type="success" @click="apiTaskOrderEndHandel" v-show="seeStatus === 4">确认完成</el-button>

          <el-button @click="show = false" v-show="seeStatus === 3">取 消</el-button>
          <el-button type="success" @click="apiConfirmRefundHandel" v-show="seeStatus === 3">确认返款</el-button>

          <el-button @click="show = false" v-show="seeStatus === 5">取 消</el-button>
          <el-button type="success" @click="apiCancelRefundHandel" v-show="seeStatus === 5">确认驳回</el-button>

          <el-button @click="show = false" v-show="seeStatus === 2">取 消</el-button>
          <el-button type="primary" @click="show = false" v-show="seeStatus === 2">确定</el-button>

          <el-button type="danger"  @click="apiOrderReviewHandel(0)" v-show="seeStatus === 1">审核不通过</el-button>
          <el-button type="success" v-show="seeStatus === 1" @click="apiOrderReviewHandel(30)">审核通过</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiOrderReview, apiConfirmRefund, apiTaskOrderEnd, apiCancelRefund } from '@/utils/api.js'

export default {
  data() {
    return {
      seeStatus: 1, //1 审核  2 查看详情 3 返款 4 确认完成
      show: false,
      activeName: '1',
      formData: {
        id: '',
        remark: '',
        buy: {
          alipay: {},
          bank: {}
        },
        userAccount: {
          id: '',
          account: "吕悦",
        },
        kw_data: {
          keyword: "ccc",
          num: 1,
          img: [
            "https://qi-se-hua.oss-cn-guangzhou.aliyuncs.com/img/202104/26/2162530_152635.jpg",
            "https://qi-se-hua.oss-cn-guangzhou.aliyuncs.com/img/202104/26/2162530_152637.jpg"
          ],
          reviews: "对对对对对对",
          size: "蓝色"
        },
        userOrder: {
          id: 152,
          enter_shop_img: "https://qi-se-hua.oss-cn-guangzhou.aliyuncs.com/img/20201223/170514-1423b00743dedf7c87a91d5ecf60b60d.png",
          fbb_url: [{name: "hb1"}, {name: "hb2"}, {name: "hb3"}],
          goods_img: "https://qi-se-hua.oss-cn-guangzhou.aliyuncs.com/img/20201223/170517-084fa727a0e0d9682c8ca569dc0b75ff.png",
          huobi_url: [{name: "fbb1"}, {name: "fbb2"}, {name: "fbb3"}],
          order_detail_keyword: "货比货比关键词",
          pay_img: "https://qi-se-hua.oss-cn-guangzhou.aliyuncs.com/img/20201223/170519-2d9688c975248c0d0e42867045aae08a.png",
          pay_order_number: "17759701303",
          real_pay_price: "10.00",
          shop_enter_keyword_url: "进店关键词链接",
          shop_name: "李金蔚旗舰店",
          receive_goods_img: '', //确认收货截图
          comment_img: '' //评价截图
        },
        userShop: { title: '' },
        platform: { title: ''},
        img: [],
        task_type: {}
      },
      form: {
        id: '',
        status: 30, // 30-审核通过  0-审核不通过
      }
    }
  },
  filters: {
    filterScreenshot(status) {
      if(status == 0) return '不截图'
      return '截图'
    }
  },
  methods: {
    // 确认驳回
    async apiCancelRefundHandel() {
      if(!this.formData.remark) return this.$message.error('请填写驳回理由')
      this.form.id = this.formData.id
      let loading = this.$loading()
      const { success, data } = await apiCancelRefund(Object.assign(this.form, { remark: this.formData.remark })).catch( () => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.onClose()
      this.$emit('emitOrderReview')
    },
    // 任务订单弹窗
    async apiOrderReviewHandel(status) {
      if(status == 0 && !this.formData.remark) return this.$message.error('请填写审核不通过理由')
      this.form.id = this.formData.id
      this.form.status = status
      let loading = this.$loading()
      const { success, data } = await apiOrderReview(Object.assign(this.form, { remark: this.formData.remark })).catch( () => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.onClose()
      this.$emit('emitOrderReview')
    },
    // status 1 审核 2 查看详情
    onShow(obj, status = 1) {
      this.seeStatus = status
      this.formData = obj
      this.show = true
    },
  
    onClose() {
      this.show = false
    },

    // 确认返款
    async apiConfirmRefundHandel() {
      let loading = this.$loading()
      const { success, data } = await apiConfirmRefund({ id: this.formData.id }).catch( () => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.onClose()
      this.$emit('emitOrderReview')
      console.log(data)
    },

    // 确认完成
    async apiTaskOrderEndHandel() {
      let loading = this.$loading()
      const { success, data } = await apiTaskOrderEnd({ id: this.formData.id }).catch( () => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.onClose()
      this.$emit('emitOrderReview')
      console.log(data)
    }

  }
}
</script>

<style lang='scss'>
.s_task_list_order_task_pop {
  .s-hr {
    margin: 20px 0;
    width: 100%;
    background: #dad8d8;
    height: 1px;
  }
  .s-user-order {
    color: $black;
    font-size: 14px;
    font-weight: bold;
    padding-left: 30px;
    padding-bottom: 30px;
  }
}
</style>