<template>
 <el-card class="v_task_list_order">
   <!-- 商家任务订单列表 商家任务订单列表 商家任务订单列表-->
    <el-form :inline="true" size="mini" :model="query" class="v-task-list-order-form">

      <el-form-item label="平台类型：">
        <el-select v-model="query.platform_id" @change="selectPlatChange">
          <el-option :label="item.title" :value="item.id" v-for="(item, index) in platFormList" :key="index" ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="时间：">
          <el-date-picker v-model="query.date_time" @change="getList()" type="datetimerange"
                          :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
      </el-form-item>

      <el-form-item label="第三方账号:">
        <el-input v-model="query.account" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
      </el-form-item>       
                
      <el-form-item label="第三方单号：">
        <el-input v-model="query.pay_order_number" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
      </el-form-item>     

      <el-form-item label="选择店铺：">
        <el-select v-model="query.user_shop_id" @change="getList()">
          <el-option :label="item.title" :value="item.id" v-for="(item, index) in storeList" :key="index" ></el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="商家店铺">
          <el-input v-model="query.shop_title" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
      </el-form-item> -->

      <el-form-item>
          <el-select slot="label" v-model="query.search_key" placeholder="查询">
              <el-option label="任务编号" value="user_task_id"></el-option>
              <el-option label="订单编号" value="id"></el-option>
          </el-select>
          <el-row>
              <el-col :span="8">
                  <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
              </el-col>
              <el-col :span="14" :offset="1">
                  <el-button type="info" @click="resetData">重置</el-button>
                  <el-button type="primary" @click="getList()">查询</el-button>
                  <el-button type="success" @click="exportClick">导出列表</el-button>
                  <el-button v-if="query.platform_id != 'ALL'" type="warning" @click="multipleClick">批量发货</el-button>
              </el-col>
          </el-row>
      </el-form-item>
  </el-form>

    <!-- s0=已取消 s10=待接单  s20=待审核  s30=操作中 s40=待返款 s50=待收货 s60=待完成 s70已完成 -->
    <el-tabs class="v-task-order-tabs-list" type="border-card" v-model="query.status" @tab-click="handleClick">
      <el-tab-pane v-for="(item,index) in statusTabList" :key="index" :label="item.title + '(' + item.number + ')'" :name="item.value"></el-tab-pane>
    </el-tabs>

    <ul class="v-task-order-list">
      <div v-if="query.platform_id != 'ALL' && fahuoOrderList.length" class="v-task-order-list-all-select g-flex-align-center" :class="selectAllClass" @click="selectAllClick">
        <i class="iconfont iconxuanzhong"></i>
        <span class="v-task-order-list-all-select-title">全选</span>
      </div>
      <li class="v-task-order-item" v-for="(item, index) in table.rows" :key="index">
        <!-- 顶部 -->
        <div class="v-task-order-item-top g-flex-align-center">
          <div class="v-task-order-item-top-left g-flex-align-center">
            
            <!-- 多选 -->
            <div v-if="query.platform_id != 'ALL' && item.userOrder.express_id == 0 && item.status === 40"  class="v-task-order-item-top-select" :class="selectOrderList.indexOf(item.user_order_id) == -1 ? '': 'v-task-order-item-top-select-active'" @click="itemOrderSelectClick(item)">
              <i class="iconfont iconxuanzhong"></i>
            </div>

            <div class="v-task-order-item-top-store g-flex-align-center">
              <i class="iconfont iconchangyonglogo25" v-show="item.platform_id === 1"></i>
              <i class="iconfont iconzhifu-jingdong" v-show="item.platform_id === 2"></i>
              <i class="iconfont iconpinduoduoa" v-show="item.platform_id === 3"></i>
              <span class="v-task-order-item-top-store-value">{{item.userShop.title}}</span>
            </div>
            <div class="v-task-order-item-top-id">
              <span class="v-task-order-item-top-id-title">订单编号:</span>
              <span class="v-task-order-item-top-id-value">{{item.id }}</span>
            </div>
            <div class="v-task-order-item-top-id">
              <span class="v-task-order-item-top-id-title">任务编号:</span>
              <span class="v-task-order-item-top-id-value">{{item.user_task_id }}</span>
            </div>

            <p class="v-task-order-item-top-type g-grey" v-show="item.status === 0">已取消</p>
            <p class="v-task-order-item-top-type g-red" v-show="item.status === 10">待接单</p>
            <p class="v-task-order-item-top-type g-yellow" v-show="item.status === 20">待审核</p>
            <p class="v-task-order-item-top-type g-blue" v-show="item.status === 30">操作中</p>
            <p class="v-task-order-item-top-type g-ceng" v-show="item.status === 40">待返款</p>
            <p class="v-task-order-item-top-type g-purple" v-show="item.status === 50">待收货</p>
            <p class="v-task-order-item-top-type" v-show="item.status === 60">待完成</p>
            <p class="v-task-order-item-top-type g-green" v-show="item.status === 70">已完成</p>

            <div class="v-task-order-item-top-id" v-show="item.user_account_id">
              <span class="v-task-order-item-top-id-title">账号:</span>
              <span class="v-task-order-item-top-id-value v-task-order-item-top-id-name">{{ item.userAccount.account }}</span>
            </div>

            <!-- 倒计时 -->
            <p class="v-task-order-item-top-count-time" v-if="item.status != 70 && item.status != 10 && item.status != 0">{{item.countTime}}</p>

          </div>
          <div class="v-task-order-item-top-right g-flex-align-center">
            <el-button size="small" type="info" class="v-task-order-item-top-right-pay" v-if="item.status == 10 && auth.taskorderconfirmdone" @click="setCancelOrder(item)">取消订单</el-button>
            
            <el-button size="small" type="success" class="v-task-order-item-top-right-pay" v-if="item.status == 60 && auth.taskorderconfirmdone" @click="setOrderPop(item, 4)">确认完成</el-button>
            
            <el-button size="small" type="primary" class="v-task-order-item-top-right-pay" v-if="item.status == 20 && auth.taskorderpass" @click="setOrderPop(item, 1)">审核</el-button>
            
            <el-button size="small" type="primary" class="v-task-order-item-top-right-pay" v-if="item.status == 40 && auth.taskorderreturnmoney" @click="setOrderPop(item, 3)">确认返款</el-button>
            <el-button size="small" type="danger" class="v-task-order-item-top-right-pay" v-if="item.status == 40 && auth.taskorderbohui" @click="setOrderPop(item, 5)">驳回</el-button>
            
            <!-- 发货按钮 -->
            <p class="v-task-order-item-top-right-again" v-if="item.userOrder.express_id == 0 && item.status === 40" @click="fahuoClick(item)">发货</p>

            <p class="v-task-order-item-top-right-see-detail" @click="setForm(item)">查看任务详情</p>
            <p class="v-task-order-item-top-right-again" @click="setOrderPop(item, 2)">查看详情</p>
            <!-- 删除图标 -->
            <!-- <div class="v-task-order-item-top-right-delete">
              <i class="iconfont iconshanchu"></i>
            </div> -->
          </div>

        </div>

        <div class="v-task-order-item-bottom g-flex-align-center">
          <div class="v-task-order-item-bottom-left g-flex-align-center">
            <img :src="item.img[0]" alt="">
            <div class="v-task-order-item-bottom-left-right">
              <div class="v-task-order-item-bottom-left-right-top" :title="item.title" @click="titleClick(item)">
                {{item.title}}
              </div>
              <div class="v-task-order-item-bottom-left-bttom">
                <div class="v-task-order-item-bottom-left-bttom-one" v-if="item.userOrder.pay_order_number">
                  <span>物流单号:</span>
                  <span>{{item.userOrder.express_no ? item.userOrder.express_no : '暂无' }}</span>
                </div>
                <div class="v-task-order-item-bottom-left-bttom-one">
                  <span>第三方订单号:</span>
                  <span>{{item.userOrder.pay_order_number ? item.userOrder.pay_order_number: '暂无' }}</span>
                </div>
                <div class="v-task-order-item-bottom-left-bttom-one">
                  <span>第三方账号:</span>
                  <span>{{item.userAccount.account ? item.userAccount.account: '暂无' }}</span>
                </div>
                <div class="v-task-order-item-bottom-left-bttom-two">
                  <span>更新时间:</span>
                  <span class="v-task-order-item-bttom-two-time">
                    {{formatDate(item.modify_time, 'yyyy-MM-dd hh:mm:ss')}}
                  </span>
                </div>
                <div class="v-task-order-item-bottom-left-bttom-two">
                  <span>发布日期:</span>
                  <span class="v-task-order-item-bttom-two-time">
                    {{formatDate(item.create_time, 'yyyy-MM-dd hh:mm:ss')}}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="v-task-order-item-bottom-right g-flex-align-center">
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center">
              <span :title="item.detail_keyword">{{item.detail_keyword}}</span>
              <p class="g-red">详情关键词</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center">
              <span>{{item.total_price}}元</span>
              <p class="g-red">总金额</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center">
              <span>{{item.ben_price}}</span>
              <p class="g-blue">本金</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center" :class="Number(item.userOrder.real_pay_price) != Number(item.ben_price) ? 'v-task-order-item-bottom-right-item-no': ''">
              <span>{{item.userOrder.real_pay_price}}元</span>
              <p class="g-green">实付本金</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center">
              <span>{{item.service_price}}元</span>
              <p class="g-yellow">基础服务费</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center">
              <span>{{ Math.formatFloat(Number(item.base_price) + Number(item.flow_price), 2) }}元</span>
              <p class="g-ceng">基础佣金</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center">
              <span>{{item.vat_price}}元</span>
              <p class="g-purple">增值服务费</p>
            </div>
            <div class="v-task-order-item-bottom-right-item g-flex-column g-flex-align-center g-flex-justify-center">
              <span>{{item.buy_num}}件</span>
              <p class="g-grey">下单数</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
    

    <div class="v-task-order-list-pagination">
      <el-pagination :page-sizes="[5, 10, 15, 20]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange"  hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>

    <task-list-order-task-pop ref="TaskListOrderTaskPop"/>

    <task-list-order-pop ref="TaskListOrderPop" @emitOrderReview="getList(false)"/>
    
    <task-list-order-export-pop ref="TaskListOrderExportPop" :platFormList="platFormList" :shopList="shopList"/>


    <!-- 发货弹窗 -->
    <task-list-order-fa-huo-pop ref="TaskListOrderFaHuoPop" @emitSaveSuccess="getList(false)"/>
 </el-card>
</template>

<script>
import { apiGetTaskOrderList, apiGetShopTypeList, apiCancelTaskOrder, apiGetShopList, apGetTaskOrderStatusTab } from '@/utils/api.js'
import { formatDate } from '@/utils/index.js'
import TaskListOrderTaskPop from './TaskListOrderTaskPop.vue'
import TaskListOrderPop from './TaskListOrderPop.vue'
import TaskListOrderExportPop from './TaskListOrderExportPop.vue'
import TaskListOrderFaHuoPop from '@/components/TaskListOrderFaHuoPop.vue'
export default {
  components: { TaskListOrderTaskPop, TaskListOrderPop, TaskListOrderExportPop, TaskListOrderFaHuoPop },
  data() {
    return {
      // 选中的订单列表
      selectOrderList: [],
      

       // 状态tab列表
      statusTabList: [],

      shopList: [],
      auth: {
        taskorderpass: this.$global.ApiList.includes(46), //任务订单审核
        taskorderreturnmoney: this.$global.ApiList.includes(47), //任务订单确认返款
        taskorderbohui: this.$global.ApiList.includes(48), //任务订单确认返款驳回
        taskorderconfirmdone: this.$global.ApiList.includes(49), //任务订单确认完成
      },
      startTime: 0,
      timeEndText: '00:00:00', 
      platFormList: [ { id: 'ALL', title: '全部'} ],
      table: { total: 0, rows: []},
      form: false,
      title: '开心掌柜',
      // 任务回收时间
      taskTimeList: [
        { name: '2小时', value: 2 },
        { name: '4小时', value: 4 },
        { name: '8小时', value: 8 },
        { name: '12小时', value: 12 },
        { name: '24小时', value: 24 },
        { name: '48小时', value: 48 },
        { name: '当天24点', value: 0 },
      ],
      query: {
          type: 0,
          status: this.$route.query.status ? this.$route.query.status : 'ALL',
          platform_id: 'ALL',
          title:'',
          date_time: [],
          pay_order_number: '',
          account:'',
          search_key: this.$route.query.taskId ? 'user_task_id': 'id', search_val: this.$route.query.id ? this.$route.query.id : (this.$route.query.taskId ? this.$route.query.taskId : ''),
          page: 1,
          limit: 10,
          shop_title: '',
          user_shop_id: 'ALL'
      },
      // 店铺列表
      storeList: []
    }
  },
  async created() {
    let status = this.$route.query.status
    if(status) this.query.status = status
    await this.apiGetShopTypeListHandel()
    await this.getList()
    await this.apiGetShopListHandel()
    await this.apiGetShopListSecondHandel()
  },
  methods: {
    // 获取店铺列表
    async apiGetShopListSecondHandel() {
      const { success, data } = await apiGetShopList({ page: 1, limit: 1000, status: 2 })
      if(!success) return
      this.storeList = [{ title: '全部', id: 'ALL'}].concat(data.rows)
    },
    formatDate,
    // 全选
    selectAllClick() {
      let arr = this.table.rows.filter(item => {
        return item.userOrder.express_id == 0 && item.status === 40
      })
      if(this.selectOrderList.length == arr.length) return this.selectOrderList = []
      this.selectOrderList = arr.map(item => item.user_order_id)
      console.log(this.selectOrderList)
    },
    // 平台类型变化
    selectPlatChange() {
      this.selectOrderList = []
      this.getList()
    },
    // 点击多选
    itemOrderSelectClick(item) {
      if(this.selectOrderList.indexOf(item.user_order_id) == -1) this.selectOrderList.push(item.user_order_id)
      else this.selectOrderList.splice(this.selectOrderList.indexOf(item.user_order_id), 1)
    },
    // 批量发货
    multipleClick() {
      if(!this.selectOrderList.length) return this.$message.error('请选择要发货的订单!')
      this.$refs.TaskListOrderFaHuoPop.onShow(this.selectOrderList.join(','), this.query.platform_id, 2)
    },

    // 发货
    fahuoClick(item) {
      this.$refs.TaskListOrderFaHuoPop.onShow(item.user_order_id, item.platform_id, 1)
    },
    handleClick() {
      this.getList()
    },

    // 状态tab列表获取
    async apGetTaskOrderStatusTabHandel() {
       let loading = this.$loading()
      const { success, data } = await apGetTaskOrderStatusTab(this.query).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.statusTabList = data.list
      console.log(data)
    },

    // 获取店铺列表
    async apiGetShopListHandel() {
      const { success, data } = await apiGetShopList({ status: 'ALL', platform_id: 'ALL', page:1, limit:100 },)
      if(!success) return
      this.shopList = data.rows.map(item => {
        return { account: item.title, id: item.id }
      })
    },


    // 点击导出
    exportClick() {
      this.$refs.TaskListOrderExportPop.onShow()
    },

    // 点击取消订单
    setCancelOrder(item) {
      this.$confirm('此操作会将该订单取消, 是否继续?', '取消订单', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.apiCancelTaskOrderHandel(item)
        }).catch(() => {});
    },

    // 取消订单
    async apiCancelTaskOrderHandel(item) {
       let loading = this.$loading()
      const { success, data } = await apiCancelTaskOrder({ id: item.id }).catch(() => { loading.close() })
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.getList(false)
    },

    // 点击重置
    resetData() {
      this.query = {
          type: 0,
          status: this.$route.query.status ? this.$route.query.status : 'ALL',
          platform_id: 'ALL',
          title:'',
          date_time: [],
          pay_order_number: '',
          account:'',
          search_key: this.$route.query.taskId ? 'user_task_id': 'id', search_val: this.$route.query.id ? this.$route.query.id : (this.$route.query.taskId ? this.$route.query.taskId : ''),
          page: 1,
          limit: 10,
          shop_title: '',
          user_shop_id: 'ALL'
      },
      this.getList()
    },

    // 点击商品标题跳转
    titleClick({ url }) {
      window.open(url)
    },
    // 获取平台列表
    async apiGetShopTypeListHandel() {
      const { success, data } = await apiGetShopTypeList()
      if(!success) return
      this.platFormList = this.platFormList.concat(data.list)
    },
    // <!-- s0=已取消 s10=待接单  s20=待审核  s30=操作中 s40=待返款 s50=待收货 s60=待完成 s70已完成 -->
    //获取列表
    async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        let loading = this.$loading()
        const { success, data } = await apiGetTaskOrderList(this.query).catch(() => { loading.close() })
        loading.close()
        if(!success) return
        this.startTime = data.nowTime * 1000
        this.table.rows = data.rows.map(item => {
          item.task_time = item.task_time * 1000
          item.countTime = ''
          return item
        })
        this.table.total = data.total
        console.log(data) 
        if(this.timer) clearInterval(this.timer)
        this.timer = setInterval(() => {
          this.table.rows.forEach((item) => {
            if(Number(item.task_time)) {
              let chazhi = item.task_time - this.startTime
              if(chazhi > 0 ) {
                let day = Math.floor(chazhi / 86400000)
                let hour = Math.floor((chazhi / 3600000) % 24)
                let min =  Math.floor((chazhi / 60000) % 60)
                let sec =  Math.floor((chazhi / 1000) % 60)
                hour = hour < 10 ? "0" + hour : hour
                min = min < 10 ? "0" + min : min
                sec = sec < 10 ? "0" + sec : sec
                if(day > 0) {
                  item.countTime = `${day}天${hour}小时${min}分${sec}秒`
                } 
                if(day <= 0 && hour > 0 ){
                  item.countTime = `${hour}小时${min}分${sec}秒`
                }
                if(day <= 0 && hour <= 0){
                  item.countTime =`${min}分${sec}秒`
                }
                item.task_time = item.task_time - 1000
              }else {
                item.countTime = this.timeEndText
              }
            }else {
              item.countTime = this.timeEndText
            }
          })
        },1000)

        this.apGetTaskOrderStatusTabHandel()

    },
    //每页数量改变
    sizeChange(limit) {
      console.log(limit)
      this.timeShow = false
      this.query.limit = limit
      this.getList()
    },
    //设置任务详情form
    setForm(formData = { id: '' }) {
      console.log(formData)
      let copyObj = JSON.parse(JSON.stringify(formData)) 
      this.$refs.TaskListOrderTaskPop.onShow(copyObj)
    },

    // 看订单详情和用户详情 status 1 审核  2 查看详情 3 返款 4 确认完成
    setOrderPop(formData = { id: '' }, status) {
      console.log(formData)
      let copyObj = JSON.parse(JSON.stringify(formData)) 
      this.$refs.TaskListOrderPop.onShow(copyObj, status)
    },

  },
  computed: {
    selectAllClass() {
      let arr = this.table.rows.filter(item => {
          return item.userOrder.express_id == 0 && item.status === 40
      })
      if(this.selectOrderList.length === arr.length) return 'v-task-order-list-all-select-active'
      else return ''
    },
    
    fahuoOrderList() {
      let arr = this.table.rows.filter(item => {
          return item.userOrder.express_id == 0 && item.status === 40
      })
      return arr
    }
  }
}
</script>

<style lang="scss">
  .v_task_list_order {
    overflow-x: auto;
    .v-task-order-tabs-list {
      width: 100%;
      min-width: 1300px;
      &.el-tabs--border-card {
        box-shadow: none;
        // .el-tabs__header {
        //   border-bottom: none;
        // }
      }
      .el-tabs__content {
        padding: 0;
      }
    }

    .v-task-order-list {
      // border: 1px solid#e6e6e6;
      width: 100%;
      min-width: 1300px;
      // 全选
      .v-task-order-list-all-select {
        padding: 10px 0;
        cursor: pointer;
        .iconfont {
          color: #cac7c7;
          font-size: 26px;
          cursor: pointer;
          padding: 0 10px 0 0;
        }
        .v-task-order-list-all-select-title {
          font-size: 16px;
        }

        &.v-task-order-list-all-select-active {
          .iconfont {
            color: #F9436B;
          }
        }
      }
      .v-task-order-item {
        border: 1px solid#e6e6e6;
        margin-top: 30px;
        &:nth-of-type(1) {
          margin-top: 0;
        }
        .v-task-order-item-top {
          padding: 10px 20px;
          font-size: 16px;
          border-bottom: 1px solid#e6e6e6;
          .v-task-order-item-top-left {
            flex: 1;
            // 多选
            .v-task-order-item-top-select {
              .iconfont {
                color: #cac7c7;
                font-size: 26px;
                cursor: pointer;
                padding: 0 10px 0 0;
              }
              &.v-task-order-item-top-select-active {
                .iconfont {
                  color: #F9436B;
                }
              }
            }

            .v-task-order-item-top-store {
              .iconfont {
                color: $mainRed;
                font-size: 26px;
              }
              .v-task-order-item-top-store-value {
                padding-left: 4px;
                color: $black;
              }
            }

            .v-task-order-item-top-id {
              padding-left: 20px;
              color: #999;
              .v-task-order-item-top-id-value {
                padding-left: 10px;
                &.v-task-order-item-top-id-name {
                  padding-left: 0px;
                }
              }
            }
            // 倒计时
            .v-task-order-item-top-count-time {
              color: $blue;
              font-size: 16px;
              padding-left: 30px;
              font-weight: bold;
            }
            
            .v-task-order-item-top-number {
              padding-left: 20px;
              color: $mainRed;
              .v-task-order-item-top-number-value {
                padding-left: 10px;
              }
            }
            .v-task-order-item-top-type {
              padding-left: 20px;
            }
          }

          .v-task-order-item-top-right {
            .v-task-order-item-top-right-see-detail {
              color: #FEB300;
              padding: 0 10px;
              cursor: pointer;
            }
            .v-task-order-item-top-right-wuliu {
              color: $red;
              cursor: pointer;
            }
            .v-task-order-item-top-right-again {
              color: $blue;
              cursor: pointer;
            }
            .v-task-order-item-top-right-pay {
              // padding: 5px 16px;
              margin: 0 10px;
            }
            .v-task-order-item-top-right-delete {
              margin-left: 20px;
              .iconfont {
                padding: 0 10px;
                font-size: 18px;
                cursor: pointer;
              }
            }
          }
        }

        .v-task-order-item-bottom {
          padding: 0 20px;
          .v-task-order-item-bottom-left {
            width: 500px;
            padding: 20px 0;
            padding-right: 20px;
            border-right: 1px dashed #e6e6e6;
            img {
              width: 100px;
              height: 100px;
              object-fit: cover;
              border-radius: 4px;
            }
            .v-task-order-item-bottom-left-right {
              padding-left: 20px;
              flex: 1;
              .v-task-order-item-bottom-left-right-top {
                width: 380px;
                font-size: 16px;
                line-height: 22px;
                font-weight: bold;
                cursor: pointer;
                @include textEllipsis(2);
                &:hover {
                  color: $blue;
                  text-decoration: underline;
                }
              }
              .v-task-order-item-bottom-left-bttom {
                color: $black;
                .v-task-order-item-bottom-left-bttom-one {
                  font-size: 14px;
                  padding-top: 10px;
                  span {
                    &:nth-last-of-type(1) {
                      color: $grey;
                      padding-left: 10px;
                    }
                  }
                }
                .v-task-order-item-bottom-left-bttom-two {
                  font-size: 14px;
                  padding-top: 10px;
                  span {
                    &.v-task-order-item-bttom-two-time {
                      color: $grey;
                      padding-left: 10px;
                    }
                  }
                }
              }
            }
          }

          .v-task-order-item-bottom-right {
            flex: 1;
            align-self: stretch;
            .v-task-order-item-bottom-right-item {
              flex: 1;
              align-self: stretch;
              padding: 20px 0;
              border-right: 1px dashed #e6e6e6;
              &.v-task-order-item-bottom-right-item-no {
                background: $blue;
                color: $white;
                p {
                  color: $white;
                }
              }
              &:nth-last-of-type(1) {
                border-right: none;
              }
              span {
                @include textEllipsis(2);
                padding: 0 5px;
                font-size: 17px;
                font-weight: bold;
                text-align: center;
              }
              p {
                padding-top: 14px;
                font-size: 14px;
                letter-spacing: 2px;
              }
            }
          }
        }
      }
    }

    .v-task-order-list-pagination {
      margin-top: 30px;
    }
  }
</style>
