<template>
 <div class="c_task_list_order_fa_huo_pop">
    <el-dialog :close-on-press-escape="false" :title="'商品发货'" class="c-task-order-wuliu-dialog" top="10vh" width="900px" :close-on-click-modal="false" @close="onClose" :visible.sync="show">
      <div class="c-fa-huo-panel">
        <p class="c-fa-huo-panel-title">请选择仓库：</p>
        <div class="c-fa-huo-panel-list-box g-flex-align-start">
          <el-cascader-panel @change="panelChange" v-model="selectExpressCode" :options="expressList" :props="panelPorps"></el-cascader-panel>
          <div class="c-fa-huo-panel-tips">
            <div class="c-fa-huo-panel-tips-address g-flex-align-start">
              <p class="c-fa-huo-panel-tips-address-title">发货地址:</p>
              <p class="c-fa-huo-panel-tips-address-value">{{selectExpressObj.senderProvince}} {{selectExpressObj.senderCity}} {{selectExpressObj.senderDistrict}} {{selectExpressObj.senderDetail}}</p>
            </div>
            <div class="c-fa-huo-panel-tips-introduce g-flex-align-start">
              <p class="c-fa-huo-panel-tips-introduce-title">发货描述:</p>
              <p class="c-fa-huo-panel-tips-introduce-value">{{selectExpressObj.expressIntro}}</p>
            </div>
          </div>
        </div>
        
      </div>

      <p class="c-fa-huo-list-title" v-show="giftList.length">请选择礼品:</p>
      <ul class="c-fa-huo-list g-flex-align-center g-flex-wrap">
        <li class="c-fa-huo-item g-flex-column g-flex-align-center" :class="giftIndex == index ? 'c-fa-huo-item-active': ''" @click="giftItemClick(item,index)" v-for="(item, index) in giftList" :key="index">
          <img :src="item.image" alt="">
          <p class="c-fa-huo-item-info-title">{{item.showName}}</p>
          <div class="c-fa-huo-item-info-box g-flex-align-center">
            <span class="c-fa-huo-item-info-fuhao">¥</span>
            <p class="c-fa-huo-item-info-price">{{item.price}}</p>
            <span class="c-fa-huo-item-info-fuhao">元</span>
          </div>
        </li>        
      </ul>

      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false" >取 消</el-button>
        <el-button type="primary" @click="fahuoClick">确认发货</el-button>
      </div>
    </el-dialog>
 </div>
</template>

<script>
import { apiGetExpressList, apiCreateOrder, apiBatchCreateOrder } from '@/utils/api.js'
export default {
  data() {
    return {
      show: false,
      type: 0,
      giftIndex: -1,

      selectExpressObj: {
        expressIntro: "",
        expressName: "",
        expressPlatform: "",
        houseName: "",
        senderCity: "",
        senderDetail: "",
        senderDistrict: "",
        senderProvince: "",
      },

      expressList: [], //所有仓库列表

      panelPorps: {
        label: 'expressName',
        value: 'expressCode',
      },

      selectExpressCode: [], //选择的仓库code

      giftList: [], //礼品列表

      form: {
        id: '',
        productId: '',
        platform_id: '',
      }
    }
  },
  methods: {
    // 点击发货
    fahuoClick() {
      if(this.type == 1) this.apiCreateOrderHandel()
      else this.apiBatchCreateOrderHandel()
    },

    // 批量发货
    async apiBatchCreateOrderHandel() {
      if(!this.form.productId) return this.$message.error('请选择礼品')
      const { success, data } = await apiBatchCreateOrder(this.form)
      if(!success) return
      this.$message.success(data.msg)
      console.log(data)
      this.onClose()
      this.$emit('emitSaveSuccess')
    },

    // 单个发货
    async apiCreateOrderHandel() {
      if(!this.form.productId) return this.$message.error('请选择礼品')
      const { success, data } = await apiCreateOrder(this.form)
      if(!success) return
      this.$message.success(data.msg)
      console.log(data)
      this.onClose()
      this.$emit('emitSaveSuccess')
    },

    // 级联变化的时候
    panelChange() {
      console.log(this.selectExpressCode)
      this.giftIndex = -1
      this.form.productId = ''
      console.log('selectExpressCode', this.selectExpressCode[0])
      if(this.selectExpressCode[0]) {
        let firstIndex = this.expressList.findIndex(item => item.expressCode == this.selectExpressCode[0])
        if(firstIndex > -1) {
            this.selectExpressObj = this.expressList[firstIndex]
            console.log('selectExpressObj', this.selectExpressObj)
            this.giftList = this.expressList[firstIndex].productList
        }
      }
    },
    
    giftItemClick(item,index) {
      this.form.productId = item.productId
      this.giftIndex = index
    },

    // ordeId 订单ids  platformId 平台id   type 1 单个 2批量
    onShow(ordeId = '', platformId = '', type = 0) {
      this.type = type
      if(ordeId) this.form.id = ordeId
      if(platformId) this.form.platform_id = platformId
      this.apiGetExpressListHandel()
      this.show = true
    },

    onClose() {
      this.selectExpressCode = []
      this.selectExpressObj =  {
        expressIntro: "",
        expressName: "",
        expressPlatform: "",
        houseName: "",
        senderCity: "",
        senderDetail: "",
        senderDistrict: "",
        senderProvince: "",
      }

      this.giftList = []
      
      this.giftIndex = -1
      this.form = {
        id: '',
        productId: '',
        platform_id: '',
      }
      this.show = false
    },

    // 获取快递 礼品 列表
    async apiGetExpressListHandel() {
      const { success, data } = await apiGetExpressList({ platform_id: this.form.platform_id })
      if(!success) return
      // 平台溢价 服务费
      let yijia = data.express_flow_price
      console.log('yijia',yijia)
      data.data.forEach(item => {
        if(item.productList.length) {
          // 价格修改成 溢价+物流费用+礼品价格
          item.productList.forEach(itemThird => {
            itemThird.price = Math.formatFloat(Number(yijia) + Number(item.siteRealSellPrice) + Number(itemThird.price), 2) 
          })
        }
      })
      this.expressList = data.data
      this.selectExpressCode = [data.data[0].expressCode]
      this.panelChange()
    },
  }
}
</script>

<style lang='scss'>
.c_task_list_order_fa_huo_pop {
  .el-dialog__body {
    padding: 10px 20px;
  }
  .c-fa-huo-panel {
    margin: 10px 0;
    .el-cascader-menu__wrap {
      height: 350px;
    }
    .c-fa-huo-panel-title {
      font-size: 16px;
      color: $black;
      padding: 10px 0;
    }
  }

    .c-fa-huo-panel-list-box {
      .c-fa-huo-panel-tips {
        padding-left: 30px;
        flex: 1;
        .c-fa-huo-panel-tips-address {
          .c-fa-huo-panel-tips-address-title {
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
          }
          .c-fa-huo-panel-tips-address-value {
            font-size: 16px;
            color: red;
            flex: 1;
            padding-left: 10px;
            line-height: 30px;
          } 
        }
        .c-fa-huo-panel-tips-introduce {
          padding-top: 20px;
          .c-fa-huo-panel-tips-introduce-title {
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
          }
          .c-fa-huo-panel-tips-introduce-value {
            font-size: 16px;
            color: red;
            flex: 1;
            padding-left: 10px;
            line-height: 30px;
          } 
        }
      }
    }

  .c-fa-huo-list-title {
    font-size: 16px;
    color: $black;
    padding: 10px 0;
  }
  .c-fa-huo-list {
    .c-fa-huo-item {
      margin: 0 10px 20px;
      cursor: pointer;
      padding: 15px 10px;
      border: 1px solid #F1F1F1;
      border-radius: 5px;
      &.c-fa-huo-item-active {
        background: #e6e2e2;
      }
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
      .c-fa-huo-item-info-title {
        font-size: 14px;
        padding: 10px 0;
      }
      .c-fa-huo-item-info-box {
        color: $red;
        .c-fa-huo-item-info-price {
          padding: 0 5px;
          font-size: 16px;
        }
        .c-fa-huo-item-info-fuhao {
          font-size: 14px;
        }
      }
    }
  }
}
</style>